import React, { useState } from "react"

import LazyAnimationImage from '../components/lazeeAnimationImage'

import Layout from "../components/layout"
import "../styles/styles.css"

import { graphql, navigate, useStaticQuery } from "gatsby"
// import { getImage } from "gatsby-plugin-image"
// import { BgImage } from "gbimage-bridge"

import { Container, Row, Col, Card, Button, Alert, Form } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

import { StaticImage } from "gatsby-plugin-image"


import Head from "../components/head"

export function OneKiloBoxes() {
  return (
    <StaticImage
      src="../images/OneKiloBoxes.jpg"
      alt="Picture of one kilo boxes of Crostoli"
      placeholder="blurred"
      layout="constrained"
      width={1000}
      aspectRatio={2.22}
    />
  )
}

export function HalfKiloBoxes() {
  return (
    <StaticImage
      src="../images/HalfKiloBoxes.jpg"
      alt="Picture of half kilo boxes of Crostoli"
      // placeholder="blurred"
      layout="constrained"
      width={1000}
      aspectRatio={2.22}
    />
  )
}

export function TreeCrostoli() {
  return (
    <StaticImage
      src="../images/CrostoliTrees-Test5.jpg"
      alt="Picture of Christmas Crostoli Trees"
      // placeholder="blurred"
      layout="constrained"
      objectPosition="bottom"
      width={1000}
      aspectRatio={2.22}
    />
  )
}


const OrderPage = ({ data }) => {

  

  // const { whiteWallBackground } = useStaticQuery(
  //   graphql`
  //     query { 
  //       whiteWallBackground: file(relativePath: {eq: "Easter2021-Background-WhiteWall.jpg"}) {
  //         childImageSharp {
  //           gatsbyImageData(
  //             width: 2000, 
  //             quality: 50, 
  //             webpOptions: {quality: 70}
  //           )
  //         }
  //       }
  //     }
  //   `
  // )

  // const sources = getImage(whiteWallBackground)
  
    
   

  const [contact, setContact] = useState({
    kiloQuantity: "0",
    halfKiloQuantity: "0",
    treeCrostoliQuantity: "0",
    // totalAmount: "0",
    firstName: "",
    lastName: "",
    telephone: "",
    email: "",
    address: "",
    address1: "",
    city: "",
    province: "",
    postalCode: "",
    deliveryWeek: "",
    deliveryDay: "",
    deliveryTime: "",
  })


  // The handleChange function below is for handling changes to the forms' input fields

  function handleChange(e) {
    const { name, value } = e.target

    setContact(prevValue => {
      return {
        ...prevValue,
        [name]: value,
      }
    })

  } 

  // The encode and handleSubmit functions below are for submitting form data to Netlify

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = e => {
    
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...contact }), 
    })
      .then(() => navigate("/soldOut"))
      .catch(error => alert(error)) 
    e.preventDefault()  
  }

  // The function below calculates the total cost of the 1kg, 1/2kg and 300g orders

  let kilo = contact.kiloQuantity
  let halfKilo = contact.halfKiloQuantity
  let treeCrostoli = contact.treeCrostoliQuantity

  // treeCrostoliQuantity

  function Total() {
    let num = (kilo * 44.99 + halfKilo * 27.99 + treeCrostoli * 20.99)
    let n = num.toFixed(2)
    return n
  }

  // The if statements below are to determine the delivery day
  // Also to block customers from ordering on certain days.

  // When Day is available, then format is `date = "Monday November 27th"` with no exclamation.

  // When Day is unavailable, then format is `date = "unavailable. Please choose a day other than Monday November 27th!"` with an exclamation.

  // Note: no "!" at end if date is unblocked, and "!" at end if date is blocked.

  console.log(contact.deliveryWeek, contact.deliveryDay);

  let date;

  if ((contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Monday")) {
    date = "unavailable. Please choose a day other than Monday November 25th!"
  } else if ((contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Tuesday")) {
    date = "unavailable. Please choose a day other than Tuesday November 26th!"
  } else if ((contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Wednesday")) {
    date = "unavailable. Please choose a day other than Wednesday November 27th!"
  } else if ((contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Thursday")) {
    date = "unavailable. Please choose a day other than Thursday November 28th!"
  } else if ((contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Friday")) {
    date = "unavailable. Please choose a day other than Friday November 29th!"
  } else if ((contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Monday")) {
    date = "unavailable. Please choose a day other than Monday December 2nd!"
  } else if ((contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Tuesday")) {
    date = "unavailable. Please choose a day other than Tuesday December 3rd!"
  } else if ((contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Wednesday")) {
    date = "unavailable. Please choose a day other than Wednesday December 4th!"
  } else if ((contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Thursday")) {
    date = "unavailable. Please choose a day other than Thursday December 5th!"
  } else if ((contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Friday")) {
    date = "unavailable. Please choose a day other than Friday December 6th!"
  } else if ((contact.deliveryWeek === "thirdWeek" && contact.deliveryDay === "Monday")) {
    date = "unavailable. Please choose a day other than Monday December 9th!"
  } else if ((contact.deliveryWeek === "thirdWeek" && contact.deliveryDay === "Tuesday")) {
    date = "unavailable. Please choose a day other than Tuesday December 10th!"
  } else if ((contact.deliveryWeek === "thirdWeek" && contact.deliveryDay === "Wednesday")) {
    date = "unavailable. Please choose a day other than Wednesday December 11th!"
  } else if ((contact.deliveryWeek === "thirdWeek" && contact.deliveryDay === "Thursday")) {
    date = "unavailable. Please choose a day other than Thursday December 12th!"
  } else if ((contact.deliveryWeek === "thirdWeek" && contact.deliveryDay === "Friday")) {
    date = "unavailable. Please choose a day other than Friday December 13th!"
  } else if ((contact.deliveryWeek === "fourthWeek" && contact.deliveryDay === "Monday")) {
    date = "unavailable. Please choose a day other than Monday December 16th!"
  } else if ((contact.deliveryWeek === "fourthWeek" && contact.deliveryDay === "Tuesday")) {
    date = "unavailable. Please choose a day other than Tuesday December 17th!"
  } else if ((contact.deliveryWeek === "fourthWeek" && contact.deliveryDay === "Wednesday")) {
    date = "unavailable. Please choose a day other than Wednesday December 18th!"
  } else if ((contact.deliveryWeek === "fourthWeek" && contact.deliveryDay === "Thursday")) {
    date = "unavailable. Please choose a day other than Thursday December 19th!"
  } else if ((contact.deliveryWeek === "fourthWeek" && contact.deliveryDay === "Friday")) {
    date = "unavailable. Please choose a day other than Friday December 20th!"
  }


  // The if statement below is to determine the delivery time AND to not display a delivery time for unavailable delivery dates.

  // Commenting out below will DISPLAY time of day.

  // Uncommenting below will BLOCK time of day.

  let time;

  if (
    (contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Monday") 
    ||
    (contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Tuesday") 
    ||
    (contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Wednesday") 
    ||
    (contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Thursday") 
    ||
    (contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Friday") 
    ||
    (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Monday") 
    ||
    (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Tuesday") 
    ||
    (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Wednesday") 
    ||
    (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Thursday") 
    ||
    (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Friday") 
    ||
    (contact.deliveryWeek === "thirdWeek" && contact.deliveryDay === "Monday") 
    ||
    (contact.deliveryWeek === "thirdWeek" && contact.deliveryDay === "Tuesday") 
    ||
    (contact.deliveryWeek === "thirdWeek" && contact.deliveryDay === "Wednesday") 
    ||
    (contact.deliveryWeek === "thirdWeek" && contact.deliveryDay === "Thursday") 
    ||
    (contact.deliveryWeek === "thirdWeek" && contact.deliveryDay === "Friday") 
    ||
    (contact.deliveryWeek === "fourthWeek" && contact.deliveryDay === "Monday") 
    ||
    (contact.deliveryWeek === "fourthWeek" && contact.deliveryDay === "Tuesday") 
    ||
    (contact.deliveryWeek === "fourthWeek" && contact.deliveryDay === "Wednesday") 
    ||
    (contact.deliveryWeek === "fourthWeek" && contact.deliveryDay === "Thursday") 
    || 
    (contact.deliveryWeek === "fourthWeek" && contact.deliveryDay === "Friday")
    ) 
    {
    time = ""
      } else {
        if (contact.deliveryTime === "Morning"){
          time = "in the morning!"
        } else if (contact.deliveryTime === "Afternoon"){
          time = "in the afternoon!"
        }
  }

  

  return (

    // <BgImage
    //   image={sources}
    //   alt="Christmas red background with white snowflakes"
    //   className="backgroundImage"
    // >

    <div 
    className="backGround">
    
    <Layout>
    <Head title="Order Page"/>

    {/* The container below is the 'Alert' advising customers to order early. */}
{/* 
    <LazyAnimationImage>
    <Container style={{minWidth: `100%`}}>
        <Row className="justify-content-md-center" style={{ marginTop: `3rem`, marginBottom: `0rem`, padding: `0` }}>
          <Col md={8}>
            <Alert className="alertMessage" variant="alert" style={{margin: 0}}>
              <Alert.Heading style={{fontSize: `2rem`}}>Christmas 2024</Alert.Heading>
              <p style={{fontSize: `1.1rem`}}>
                Due to high demand at this time of year, we strongly recommend placing your Christmas orders as soon as possible. We stop accepting orders once our production schedule fills to capacity.
              </p>
              <hr className="alertMessage" />
              <p className="mb-0" style={{fontSize: `1.1rem`}}>
                Please order early to avoid disappointment!
              </p>
            </Alert>
          </Col>
        </Row>
      </Container>
      </LazyAnimationImage> */}

      {/* Below is the SOLD OUT alert */}

      
      <LazyAnimationImage>
      <Container>
        <Row className="justify-content-md-center" style={{ marginTop: `3rem`, marginBottom: `0rem`, padding: `0` }}>
          <Col md={8}>
            <Alert className="alertMessage" variant="alert" style={{margin: 0}}>
              <Alert.Heading style={{fontSize: `4rem`}}>SOLD OUT</Alert.Heading>
              <p>
                We've reached capacity for Christmas 2024 and we've stopped accepting orders.
              </p>
              <hr className="alertMessage" />
              <p className="mb-0">
                We sincerely apologize for the inconvenience.
              </p>
            </Alert>
          </Col>
        </Row>
      </Container>
      </LazyAnimationImage>
      

      
      <Container style={{minWidth: `90%`}}>
      
        <Form 
          onSubmit={handleSubmit}
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          netlify
        >
          <input type="hidden" name="form-name" value="contact" />
          <LazyAnimationImage>
          <Row>
            <Col md={4}>
              <Card border="dark" className="mb-3 spacing">
                <OneKiloBoxes />
                <Card.Body>
                  <Card.Title>
                    <h2>One Kilogram Crostoli Gift Boxes</h2>
                  </Card.Title>
                  <Card.Text>
                    <h3 className="oneBoxSpace">$44.99</h3>
                  </Card.Text>

                  {/* 1kg box quantity */}

                    <Form.Label
                      // className="my-1 mr-2 text-center"
                      // htmlFor="inlineFormCustomSelectPref"
                      htmlFor="One-Kilo-Box"
                    >
                      Currently available - 1kg Vanilla Crostoli Box
                    </Form.Label>
                    <Form.Control
                      as="select"
                      className="my-1 mr-sm-2"
                      id="One-Kilo-Box"
                      custom
                      onChange={handleChange}
                      name="kiloQuantity"
                      value={contact.kiloQuantity}
                      style={{width: `100%`, height: `3rem` }}
                    >
                      <option value="0">Choose quantity...</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                      <option value="32">32</option>
                      <option value="33">33</option>
                      <option value="34">34</option>
                      <option value="35">35</option>
                      <option value="36">36</option>
                      <option value="37">37</option>
                      <option value="38">38</option>
                      <option value="39">39</option>
                      <option value="40">40</option>
                      <option value="41">41</option>
                      <option value="42">42</option>
                      <option value="43">43</option>
                      <option value="44">44</option>
                      <option value="45">45</option>
                      <option value="46">46</option>
                      <option value="47">47</option>
                      <option value="48">48</option>
                      <option value="49">49</option>
                      <option value="50">50</option>
                      <option value="51">51</option>
                      <option value="52">52</option>
                      <option value="53">53</option>
                      <option value="54">54</option>
                      <option value="55">55</option>
                      <option value="56">56</option>
                      <option value="57">57</option>
                      <option value="58">58</option>
                      <option value="59">59</option>
                      <option value="60">60</option>
                      <option value="61">61</option>
                      <option value="62">62</option>
                      <option value="63">63</option>
                      <option value="64">64</option>
                      <option value="65">65</option>
                      <option value="66">66</option>
                      <option value="67">67</option>
                      <option value="68">68</option>
                      <option value="69">69</option>
                      <option value="70">70</option>
                      <option value="71">71</option>
                      <option value="72">72</option>
                      <option value="73">73</option>
                      <option value="74">74</option>
                      <option value="75">75</option>
                      <option value="76">76</option>
                      <option value="77">77</option>
                      <option value="78">78</option>
                      <option value="79">79</option>
                      <option value="80">80</option>
                      <option value="81">81</option>
                      <option value="82">82</option>
                      <option value="83">83</option>
                      <option value="84">84</option>
                      <option value="85">85</option>
                      <option value="86">86</option>
                      <option value="87">87</option>
                      <option value="88">88</option>
                      <option value="89">89</option>
                      <option value="90">90</option>
                      <option value="91">91</option>
                      <option value="92">92</option>
                      <option value="93">93</option>
                      <option value="94">94</option>
                      <option value="95">95</option>
                      <option value="96">96</option>
                      <option value="97">97</option>
                      <option value="98">98</option>
                      <option value="99">99</option>
                      <option value="100">100</option>
                    </Form.Control>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card border="dark" className="mb-3 spacing">
                <HalfKiloBoxes />
                <Card.Body>
                  <Card.Title>
                    <h2>Half Kilogram Crostoli Gift Boxes</h2>
                  </Card.Title>
                  <Card.Text>
                    <h3 className="halfBoxSpace">$27.99</h3>
                  </Card.Text>

                  {/* 1/2kg box quantity */}

                    <Form.Label
                      // className="my-1 mr-2 text-center"
                      htmlFor="Half-Kilo-Box"
                    >
                      Currently available - 1/2kg Vanilla Crostoli Box
                    </Form.Label>
                    <Form.Control
                      as="select"
                      className="my-1 mr-sm-2"
                      id="Half-Kilo-Box"
                      custom
                      onChange={handleChange}
                      name="halfKiloQuantity"
                      value={contact.halfKiloQuantity}
                      style={{width: `100%`, height: `3rem` }}
                    >
                      <option value="0">Choose quantity...</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                      <option value="32">32</option>
                      <option value="33">33</option>
                      <option value="34">34</option>
                      <option value="35">35</option>
                      <option value="36">36</option>
                      <option value="37">37</option>
                      <option value="38">38</option>
                      <option value="39">39</option>
                      <option value="40">40</option>
                      <option value="41">41</option>
                      <option value="42">42</option>
                      <option value="43">43</option>
                      <option value="44">44</option>
                      <option value="45">45</option>
                      <option value="46">46</option>
                      <option value="47">47</option>
                      <option value="48">48</option>
                      <option value="49">49</option>
                      <option value="50">50</option>
                      <option value="51">51</option>
                      <option value="52">52</option>
                      <option value="53">53</option>
                      <option value="54">54</option>
                      <option value="55">55</option>
                      <option value="56">56</option>
                      <option value="57">57</option>
                      <option value="58">58</option>
                      <option value="59">59</option>
                      <option value="60">60</option>
                      <option value="61">61</option>
                      <option value="62">62</option>
                      <option value="63">63</option>
                      <option value="64">64</option>
                      <option value="65">65</option>
                      <option value="66">66</option>
                      <option value="67">67</option>
                      <option value="68">68</option>
                      <option value="69">69</option>
                      <option value="70">70</option>
                      <option value="71">71</option>
                      <option value="72">72</option>
                      <option value="73">73</option>
                      <option value="74">74</option>
                      <option value="75">75</option>
                      <option value="76">76</option>
                      <option value="77">77</option>
                      <option value="78">78</option>
                      <option value="79">79</option>
                      <option value="80">80</option>
                      <option value="81">81</option>
                      <option value="82">82</option>
                      <option value="83">83</option>
                      <option value="84">84</option>
                      <option value="85">85</option>
                      <option value="86">86</option>
                      <option value="87">87</option>
                      <option value="88">88</option>
                      <option value="89">89</option>
                      <option value="90">90</option>
                      <option value="91">91</option>
                      <option value="92">92</option>
                      <option value="93">93</option>
                      <option value="94">94</option>
                      <option value="95">95</option>
                      <option value="96">96</option>
                      <option value="97">97</option>
                      <option value="98">98</option>
                      <option value="99">99</option>
                      <option value="100">100</option>
                    </Form.Control>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card border="dark" className="mb-3 spacing">
       
                <TreeCrostoli />
                
                <Card.Body>
                  <Card.Title>
                    <h2>Three hundred grams Crostoli</h2>
                    {/* <h2>300g Christmas Crostoli Trees</h2> */}
                  </Card.Title>
                  <Card.Text>
                    <h3 className="treeSpace">$20.99</h3>
                  </Card.Text>

                  {/* 300g tree quantity */}

                    <Form.Label
                      // className="my-1 mr-2 text-center"
                      htmlFor="300g-Tree"
                    >
                      Currently available - 300g Vanilla Crostoli Tree
                    </Form.Label>
                    <Form.Control
                      as="select"
                      className="my-1 mr-sm-2"
                      id="300g-Tree"
                      custom
                      onChange={handleChange}
                      name="treeCrostoliQuantity"
                      value={contact.treeCrostoliQuantity}
                      style={{width: `100%`, height: `3rem` }}
                    >
                      <option value="0">Choose quantity...</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                      <option value="32">32</option>
                      <option value="33">33</option>
                      <option value="34">34</option>
                      <option value="35">35</option>
                      <option value="36">36</option>
                      <option value="37">37</option>
                      <option value="38">38</option>
                      <option value="39">39</option>
                      <option value="40">40</option>
                      <option value="41">41</option>
                      <option value="42">42</option>
                      <option value="43">43</option>
                      <option value="44">44</option>
                      <option value="45">45</option>
                      <option value="46">46</option>
                      <option value="47">47</option>
                      <option value="48">48</option>
                      <option value="49">49</option>
                      <option value="50">50</option>
                      <option value="51">51</option>
                      <option value="52">52</option>
                      <option value="53">53</option>
                      <option value="54">54</option>
                      <option value="55">55</option>
                      <option value="56">56</option>
                      <option value="57">57</option>
                      <option value="58">58</option>
                      <option value="59">59</option>
                      <option value="60">60</option>
                      <option value="61">61</option>
                      <option value="62">62</option>
                      <option value="63">63</option>
                      <option value="64">64</option>
                      <option value="65">65</option>
                      <option value="66">66</option>
                      <option value="67">67</option>
                      <option value="68">68</option>
                      <option value="69">69</option>
                      <option value="70">70</option>
                      <option value="71">71</option>
                      <option value="72">72</option>
                      <option value="73">73</option>
                      <option value="74">74</option>
                      <option value="75">75</option>
                      <option value="76">76</option>
                      <option value="77">77</option>
                      <option value="78">78</option>
                      <option value="79">79</option>
                      <option value="80">80</option>
                      <option value="81">81</option>
                      <option value="82">82</option>
                      <option value="83">83</option>
                      <option value="84">84</option>
                      <option value="85">85</option>
                      <option value="86">86</option>
                      <option value="87">87</option>
                      <option value="88">88</option>
                      <option value="89">89</option>
                      <option value="90">90</option>
                      <option value="91">91</option>
                      <option value="92">92</option>
                      <option value="93">93</option>
                      <option value="94">94</option>
                      <option value="95">95</option>
                      <option value="96">96</option>
                      <option value="97">97</option>
                      <option value="98">98</option>
                      <option value="99">99</option>
                      <option value="100">100</option>
                    </Form.Control>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          </LazyAnimationImage>

          {/* Calculating Total amount for customer */}

          <LazyAnimationImage>
            <Card border="dark">
            {/* The styling below will make the "please ensure your order...!" message the colour red when it's displayed */}
              <Card.Body style={Total() > 150 || Total() < 0.01 ? {fontSize: `3rem`} : {fontSize: `3rem`, color: `rgb(203, 0, 24)`}}>
                
                    
                
                        {Total() > 150 || Total() < 0.01
                        ? "Your total is $" + 
                        
                     
                        Total()
                    
                        : "Please ensure your order surpasses the $150 minimum!"}

                    
      
              </Card.Body>
            </Card>
          </LazyAnimationImage>

          {/* Customer Information Title (no form inputs here) */}

          
          <Card border="dark" style={{ width: `100%`, marginTop: `3rem` }}>
          <LazyAnimationImage>
            <Card.Header style={{ fontSize: `2rem` }}>
              Customer Information
                <p className="text-muted" style={{ fontSize: `0.8rem`, marginTop: `0.3rem` }}>
                  We'll never share your information with anyone else.&ensp;Fields marked with an * are required.
                </p>
            </Card.Header>
          </LazyAnimationImage>

          {/* Customer Information form inputs begin here */}

            <Card.Body style={{textAlign: `left`}}>
              <Form.Row> 
                {/* <Row className="mb-3"> */}
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>First Name *</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      onChange={handleChange}
                      name="firstName"
                      value={contact.firstName}
                    /> 
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>Last Name *</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      onChange={handleChange}
                      name="lastName"
                      value={contact.lastName}
                    /> 
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="6" controlId="formGridTelephone">
                    <Form.Label>Telephone Number *</Form.Label>
                    <Form.Control
                      required
                      // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      type="tel"
                      onChange={handleChange}
                      name="telephone"
                      value={contact.telephone}
                      placeholder="eg. 012-345-6789"
                      // title="Please input a valid phone number"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6"  controlId="formGridEmail">
                    <Form.Label>Email *</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      onChange={handleChange}
                      name="email"
                      value={contact.email}
                      placeholder="eg. crostoli@example.com"
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="formGridAddress1">
                    <Form.Label>Address *</Form.Label>
                    <Form.Control
                      required
                      onChange={handleChange}
                      name="address"
                      value={contact.address}
                    />
                  </Form.Group>
                </Form.Row>
                
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="formGridCity">
                    <Form.Label>City *</Form.Label>
                    <Form.Control
                      required
                      as="select"
                      onChange={handleChange}
                      name="city"
                      value={contact.city}
                    >
                      <option selected disabled value="">Choose...</option>
                      <option value="Toronto">Toronto</option>
                      <option value="Mississauga">Mississauga</option>
                      <option value="Brampton">Brampton</option>
                      <option value="Vaughan">Vaughan</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="formGridProvince">
                    <Form.Label>Province</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      name="province"
                      value="Ontario"
                      // {contact.province}
                      placeholder="Ontario"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md="4"  controlId="formGridPostalCode">
                    <Form.Label>Postal Code *</Form.Label>
                    <Form.Control
                      required
                      onChange={handleChange}
                      name="postalCode"
                      value={contact.postalCode}
                      placeholder="eg. X1Y-2Z3"
                    />
                  </Form.Group>
                </Form.Row>
            </Card.Body>
          </Card>


          {/* Delivery Information */}


          <Card
            border="dark"
            style={{ width: `100%`, marginTop: `3rem`, marginBottom: `3rem` }}
          >
            <Card.Header style={{ fontSize: `2rem` }}>
              Delivery Preferences
            </Card.Header>
            <Card.Body style={{textAlign: `left`}}>
                <Form.Group
                  as={Col}
                  controlId="validationWeekPreference"
                  className="text-left"
                >
                  <Form.Label>Preferred Delivery Week</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    defaultValue="Choose..."
                    onChange={handleChange}
                    name="deliveryWeek"
                    value={contact.deliveryWeek}
                    style={{marginBottom: `1rem`}}
                  >
                    <option selected disabled value="">Choose...</option>
                    <option value="firstWeek">
                      Four weeks before Christmas (Nov 25th - Nov 29th)
                    </option>
                    <option value="secondWeek">
                      Three weeks before Christmas (Dec 2nd - Dec 6th)
                    </option>
                    <option value="thirdWeek">
                      Two weeks before Christmas (Dec 9th - Dec 13th)
                    </option>
                    <option value="fourthWeek">
                      One week before Chrismtas (Dec 16th - Dec 20th)
                    </option>
                  </Form.Control> 
                </Form.Group>
                <Form.Group
                  as={Col}
                  controlId="validationDayPreference"
                  className="text-left"
                >
                  <Form.Label>Preferred Delivery Day</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    defaultValue="Choose..."
                    onChange={handleChange}
                    name="deliveryDay"
                    value={contact.deliveryDay}
                    style={{marginBottom: `1rem`}}
                  >
                    <option selected disabled value="">Choose...</option>
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                  </Form.Control> 
                </Form.Group>
                <Form.Group
                  as={Col}
                  controlId="validationTimePreference"
                  className="text-left"
                >
                  <Form.Label>Preferred Delivery Time</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    defaultValue="Choose..."
                    onChange={handleChange}
                    name="deliveryTime"
                    value={contact.deliveryTime}
                    style={{marginBottom: `1rem`}}
                  >
                    <option selected disabled value="">Choose...</option>
                    <option value="Morning">Morning (9am - noon)</option>
                    <option value="Afternoon">Afternoon (noon - 3pm)</option>
                  </Form.Control>
                </Form.Group>

                  {/* The logic below was in the style attribute within the h1 above to make only the unavailable dates red. */}

                  {/* To display in BLACK, comment out below */}

                  {/* To display in RED, uncomment below */}

                  
                  <h1 style={(
                    (contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Monday") 
                    ||
                    (contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Tuesday") 
                    ||
                    (contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Wednesday") 
                    ||
                    (contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Thursday") 
                    ||
                    (contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Friday") 
                    ||
                    (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Monday") 
                    ||
                    (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Tuesday") 
                    ||
                    (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Wednesday") 
                    ||
                    (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Thursday") 
                    ||
                    (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Friday") 
                    ||
                    (contact.deliveryWeek === "thirdWeek" && contact.deliveryDay === "Monday") 
                    ||
                    (contact.deliveryWeek === "thirdWeek" && contact.deliveryDay === "Tuesday") 
                    ||
                    (contact.deliveryWeek === "thirdWeek" && contact.deliveryDay === "Wednesday") 
                    ||
                    (contact.deliveryWeek === "thirdWeek" && contact.deliveryDay === "Thursday") 
                    ||
                    (contact.deliveryWeek === "thirdWeek" && contact.deliveryDay === "Friday") 
                    ||
                    (contact.deliveryWeek === "fourthWeek" && contact.deliveryDay === "Monday") 
                    ||
                    (contact.deliveryWeek === "fourthWeek" && contact.deliveryDay === "Tuesday") 
                    ||
                    (contact.deliveryWeek === "fourthWeek" && contact.deliveryDay === "Wednesday") 
                    || 
                    (contact.deliveryWeek === "fourthWeek" && contact.deliveryDay === "Thursday") 
                    || 
                    (contact.deliveryWeek === "fourthWeek" && contact.deliveryDay === "Friday")
                    ) 
                    ? 
                    {color: `rgb(203, 0, 24)`, fontSize: `2rem`, marginTop: `1.5rem`, textAlign: `center`} 
                    : 
                    {color: `rgb(0, 0, 0)`, fontSize: `2rem`, marginTop: `1.5rem`, textAlign: `center`}}>

                    Your chosen delivery day is {date} {time}
                
              </h1>
            </Card.Body>
          </Card>


          {/* Below is delviery information notice */}


          <Container
            className="justify-content-md-center"
            style={{ minWidth: `100%`, marginTop: `4rem`, marginBottom: `8rem` }}
          >
            <Alert className="alertMessage" variant="alert" style={{fontSize: `1.3rem`}}>
              <p>
                We will contact you within one business day to confirm your order
                and delivery day.
              </p>
              <hr className="alertMessage"/>
              <p className="mb-0">
                We are processing credit card payments by
                phone to ensure contactless delivery.
              </p>
            </Alert>
          </Container>


          {/* Below is submit order button */}

              <Button
                variant="default"
                size="lg"
                className="submit-button"
                block
                type="submit"
                style={{
                  marginTop: `4em`,
                  marginBottom: `10rem`
                  }}
              >
                Submit your order
              </Button>
        </Form>
      </Container>
    </Layout>

    </div>
    // </BgImage>
  
  )
}

export default OrderPage


